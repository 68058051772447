import axios from "axios";

const apiUrl = 'https://admin.codingx.app';

export const getUserCertificates = async (email, app) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  try {
    const response = await axios.get(
      `${apiUrl}/users/certificates?email=${email}`,
      { headers }
    );
    return { images: response.data.data.map(certificate => certificate.imageUrl) || [], courses: response.data.data.map(certificate => certificate.id) || [] };
  } catch (e) {
    return { images: [], courses: [] };
  }
};

export const updateCertificateName = async (email, name, courseId, app) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  try {
    await axios.put(
      `${apiUrl}/certificates/${app === "CodingX" ? courseId : app}`,
      { email, name },
      { headers }
    );
    return true;
  } catch (e) {
    return false;
  }
};

export const userLogin = async (username, password, app) => {
  const headers = {
    "Content-Type": "application/json",
    app,
  };
  try {
    const response = await axios.post(
      `${apiUrl}/login`,
      { username, password },
      { headers }
    );
    return response.data.data.login;
  } catch (e) {
    return false;
  }
}
