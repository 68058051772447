import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Copyright } from '../components/copyright';
import { userLogin } from '../services/api';
import { toast } from 'react-toastify';
import { getColorScheme } from '../services/misc';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { Divider } from '@mui/material';
import { Typography } from '@mui/material';

export default function Login(props) {
    const { setEmail, setButtonDisabled, buttonDisabled, setLogin, app, setSelectApp } = props;
    const colorScheme = getColorScheme(app);
    const mainColor = colorScheme.main;
    const subColor = colorScheme.sub;

    const handleSubmit = async (event) => {
        event.preventDefault();
        setButtonDisabled(true);
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');
        const userid = data.get('userid');


        const validUser = await userLogin(email, password, app);
        if (validUser) {
            setLogin(true);
        }
        if (email && !validUser) {
            toast('Failed to login.');
        }
        if (!email) {
            toast('Please enter a valid email ID.');
        }
        setEmail(userid);
        setButtonDisabled(false);
    };
    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <IconButton
                variant="contained"
                disabled={buttonDisabled}
                sx={{
                    borderColor: subColor, '&:hover': {
                        borderColor: mainColor,
                        backgroundColor: mainColor,
                        color: 'white'
                    },
                    backgroundColor: subColor, position: 'absolute', right: 20, top: 20, color: 'white'
                }}
                onClick={() => setSelectApp(false)}
            >
                <LogoutIcon />
            </IconButton>
            <Grid
                item
                xs={false}
                sm={4}
                md={6}
                sx={{
                    background: `linear-gradient(to right bottom, ${mainColor}, ${subColor})`
                }}
            >
            </Grid>
            <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Admin Username"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: mainColor
                                    }
                                },
                                "& .MuiInputLabel-outlined.Mui-focused": {
                                    color: subColor
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            autoComplete="password"
                            autoFocus
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: mainColor
                                    }
                                },
                                "& .MuiInputLabel-outlined.Mui-focused": {
                                    color: subColor
                                },
                                mr: -20,
                                mb: 5
                            }}
                        />
                        <Divider >
                            <Typography>User Data</Typography>
                        </Divider>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="userid"
                            label="User Email Id"
                            name="userid"
                            autoComplete="userid"
                            autoFocus
                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                        borderColor: mainColor
                                    }
                                },
                                "& .MuiInputLabel-outlined.Mui-focused": {
                                    color: subColor
                                },
                                mr: -20,
                                mt: 5
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            disabled={buttonDisabled}
                            sx={{
                                mt: 3, mb: 2, backgroundColor: subColor, '&:hover': {
                                    backgroundColor: mainColor
                                }
                            }}
                        >
                            Login
                        </Button>
                        <Copyright sx={{
                            mt: 5, mb: 5,
                            color: mainColor
                        }} />
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}